<template>
    <div class="auth-container-title">Forgot Password?</div>

    <div class="reset-instructions margin-top">
        <small>

            <p>
                Enter the email address you used when you joined and we’ll send you instructions to reset your password.

            </p>
        </small>
        <br>
        <small>

            <p>
                For security reasons, we do NOT store your password. So rest assured that we will never send your password
                via
                email.
            </p>
        </small>
    </div>

    <div class="form-container">
        <label for="Email Address">Email Address</label>
        <input type="email" name="email" v-model="email" :aria-invalid="!isEmailValid">
        <p class="error-text font-small" v-if="!isEmailValid">* Enter a valid email</p>
    </div>
    <LoadingButton type="submit" class="primary-btn margin-top" @click="sendResetInstructions"
        :disabled="email == null || !isEmailValid" :loading="loading">

        Send Reset Instructions
    </LoadingButton>
</template>

<script>
import LoadingButton from '@/components/buttons/LoadingButton.vue';
import { useToast } from 'vue-toastification';
var toast = useToast()
export default {
    components: {
        LoadingButton
    },
    data() {
        return {
            email: null,
            loading: false
        }
    },
    computed: {
        isEmailValid() {
            if (this.email != null) {

                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(this.email);
            }
            return true
        }
    },
    methods: {

        sendResetInstructions() {
            if (this.email != null && this.isEmailValid) {
                this.loading = true
                // Send reset instructions to email
                this.$store.dispatch('user/sendResetPasswordEmail', this.email).then(() => {
                    toast.success("A password reset link has been sent to your email")
                }).catch(e => {
                    if (e.response) {
                        if (e.response.status == 404) {
                            toast.error("User with that email does not exist")
                        }
                    }
                    else {
                        toast.error("An error occured while sending reset instructions")
                    }
                }).finally(() => {
                    this.loading = false
                })
            }

            // this.$router.replace({name:'login'})
        }
    }
}
</script>