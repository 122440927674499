import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/style.scss'
import vue3GoogleLogin from 'vue3-google-login'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import moment from 'moment/moment'
import axios from './plugins/axios-plugin'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
var app = createApp(App)
app.use(axios,{
    'baseUrl':'https://api.n2game.co.ke/'
})
app.use(vue3GoogleLogin,{
    clientId: '409302047127-tpcvitnaek07bqj4nk0vftvv01caq6fb.apps.googleusercontent.com',
    redirectUri:"https://n2game.co.ke",
    autoLogin:true,
    prompt:true

})
const toastOptions = {}
app.use(Toast, toastOptions);
app.use(store)
app.use(autoAnimatePlugin)
app.use(router)
app.mount('#app')
app.config.globalProperties.$moment = moment
