<template>
    <InfoContainer>
            <template v-slot:header>
                <img src="@/assets/logo.png" alt="N2 header" class="logo">
                <p id="header-caption">Do you believe in your mathematical capabilities. Test yourself.</p>
            </template>

            <template v-slot:subheader>

                <h3 class='medium-header' id="difficulty-level-header">Choose Your Difficulty Level</h3>
            </template>

            <template v-slot:body>
                <div class="difficulty-selector row">
                    <DifficultyCard :level="1" @click="selected_level = 1" :selected="selected_level === 1" />
                    <DifficultyCard :level="2" @click="selected_level = 2" :selected="selected_level === 2" />
                    <DifficultyCard :level="3" @click="selected_level = 3" :selected="selected_level === 3" />
                    <DifficultyCard :level="4" @click="selected_level = 4" :selected="selected_level === 4" />

                </div>
            </template>
            <template v-slot:footer>
                <button class="primary-btn" @click="difficultySet">
                    <div class="row">
                        Proceed
                        <i class="fa fa-chevron-right" aria-hidden="true"></i>

                    </div>

                </button>

            </template>

        </InfoContainer>
</template>

<script>
import InfoContainer from '@/components/InfoContainer.vue'
import DifficultyCard from '@/components/DifficultyCard.vue'
export default{
    name: 'DifficultySelection',
    data(){
        return{
            selected_level: 1
        }
    },
    components:{
        InfoContainer,
        DifficultyCard
    },
    methods:{
        difficultySet(){
            this.$store.dispatch('game/setLevel', this.selected_level)
            this.$router.push({name:'game_stake'})

        }
    }
}

</script>