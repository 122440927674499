<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';
const props = defineProps({
  percentage: {
    type: Number,
    required: true
  },
  value:{
    type: Number,
    required: true,
    default: 0
  }
})

const cssTransformRotateValue = computed(() => {
  const percentageAsFraction = props.percentage / 100
  const halfPercentage = percentageAsFraction / 2

  return `${halfPercentage}turn`
})

const fillColor = computed(()=>{
  if (props.percentage > 50) {
    return '#2c8132'
  } else if (props.percentage > 25) {
    return '#ffba00'
  }
  else if(props.percentage<=25){
    return '#bd0000'
  } 
  
  else {
    return '#f7f89f'
  }
})

</script>

<template>
  <div class="gauge__outer">
    <div class="gauge__inner">
      <div class="gauge__fill width-grow-element" :style="{ transform: `rotate(${cssTransformRotateValue})`,background:fillColor }"></div>
      <div class="gauge__cover">
        {{ value }}
      </div>
    </div>
  </div>
</template>



<style scoped>
.gauge__outer {
  width: 100%;
  max-width: 250px;
}

.gauge__inner {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  /* background: #009578; */
  transform-origin: center top;
  transform: rotate(0turn);
  transition: transform 1s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #ffffff;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
  /* font-family: 'Lexend', sans-serif; */
  font-weight: bold;
  font-size: 38px;
}
</style>