<template>
    <p class="auth-container-title">
        Verify your email address
    </p>
    <div class="margin-top" id="email-verify-text">

        <small class="margin-top">
            <strong>
                <p>

                    Thank you for signing up!
                </p>
            </strong>
            <p>

                We've just sent a verification email to the address you provided. Please check your
                inbox
                for an email from us. If you don't see the email in your inbox, please also check your spam or junk
                folder.
            </p>
            <p>

                To complete the registration process, click on the verification link in the email. Once your email is
                verified,
                you'll be able to access all the features and benefits of our platform.
            </p>

        </small>
    </div>
    <button class="primary-btn margin-top" @click="$router.push({name:'game'})">Play a Game</button>
</template>