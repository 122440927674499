<template>
    <button :disabled="disabled">
        <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="30" color="white" v-if="loading"/>
        <slot v-else></slot>
    </button>
</template>

<script>
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
export default{
    components: {
        FulfillingBouncingCircleSpinner
    },
    props:{
        loading: {
            type: Boolean,
            default: false
        },
        disabled:{
            type: Boolean,
            default: false
        }
    }
}
</script>