<template>
    <p class="auth-container-title">New Here? <span> Create Account</span></p>

    <div class="form-container">
        <GoogleButton :primary="true" text="Sign up with Google"></GoogleButton>
        <hr class="divider sign-up">

        <router-link :to="{ name: 'register-email' }">
            <button class="secondary-btn"> Continue with Email</button>
        </router-link>
    </div>
</template>

<script>
import GoogleButton from '@/components/buttons/GoogleButton.vue'
export default {
    name: 'RegisterView',
    components: {
        GoogleButton
    },
    

}
</script>