import { createStore } from 'vuex'
import game from './game'
import user from './user'
import wallet from './wallet'
export default createStore({
  state: {
    isSideBarOpen:true
  },
  getters: {
  },
  mutations: {
    toggleSideBar(state){
      state.isSideBarOpen = false
    }
  },
  actions: {
  },
  modules: {
    game,user,wallet
  }
})
