<template>
    <div class="game-circle" :class="status" @click="circleClicked" :style="transitionStyle">

        {{ number1 }} {{ operation }} {{ number2 }} = {{ assumed_answer }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

    name: 'GameCircle',
    computed: {
        ...mapGetters('game', ['getGameSpeed', 'getLevel', 'getCircleIntervalTime']),

        transitionStyle() {
            return {
                transition: "top 2s ease-in-out"
            }
        }
    },
    props: {
        number1: {
            required: true
        },
        number2: {
            required: true
        },
        operation: {
            required: true
        },
        assumed_answer: {
            required: true
        },

        id: {
            required: true
        },
        x: {
            required: true
        }


    },

    data() {
        return {
            status: 'pending',
            intervalId: null
        }
    },

    methods: {

        playSoundCorrect(){
            //play sound effect using javascript
            var audio = new Audio(require('@/assets/sounds/correct_effect.mp3'));
            
            
            return audio.play();
        },
        playSoundWrong(){
            var audio = new Audio(require('@/assets/sounds/wrong_effect.mp3'));
            
            return audio.play();
        },
        playSoundMiss(){
            var audio = new Audio(require('@/assets/sounds/miss_effect.mp3'));
            
            return audio.play();
        },
        answer() {
            var props = this.$props;
            var num1 = props.number1;
            var num2 = props.number2;

            switch (this.$props.operation) {
                case "+":
                    return num1 + num2;
                case "-":
                    return num1 - num2;
                case "x":
                    return num1 * num2;
                case "*":
                    return num1 * num2;
                case "/":
                    return num1 / num2;
            }
        },

        isCorrect() {
            return this.$props.assumed_answer == this.answer();
        },
        circleClicked() {

            if (this.isCorrect()) {
                this.status = 'correct';
                this.playSoundCorrect().finally(()=>{

                    this.$store.dispatch('game/setCorrect',)
                }).catch(e=>{
                    console.log(e)
                })
                // clearInterval(this.intervalId)
                // this.$emit('correct', this.$props.id);
            }
            else {
                this.status = 'wrong';
                this.playSoundWrong().finally(()=>{

                    this.$store.dispatch('game/setWrong',)
                }).catch(e=>{
                    console.log(e)
                })
                // clearInterval(this.intervalId)

                // this.$emit('wrong', this.$props.id);
            }
            this.$emit('outOfScreen', this.$props.id)

        },
        getPositionFactor(index) {
            var positions = [0.0, 0.25, 0.5, 0.75, 1];
            return positions[index]

        }

    },



    mounted() {
        var element = this.$el;
        // var x = this.getRandomPosition(this.$props.id,(window.innerWidth-100));
        var x = this.getPositionFactor(this.$props.x) * 85;
        var y = 0;
        var speed = 4;
        var direction = 10;

        element.style.top = y + "px";
        element.style.left = x + "%";


        this.intervalId = setInterval(() => {
            y += speed * direction;
            element.style.top = y + "px";


            if (y > window.innerHeight - window.innerHeight * 0.1) {
                if (this.status === 'pending' && this.isCorrect()) {

                    this.status = 'missed';
                    this.playSoundMiss().finally(()=>{

                        this.$store.dispatch('game/setMissed',)
                    }).catch(e=>{
                        console.log(e)
                    })
                }
                this.$emit('outOfScreen', this.$props.id)
                clearInterval(this.intervalId)
            }



        }, this.getCircleIntervalTime);

    }
}

</script>