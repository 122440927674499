<template>
    <section class="page-content" id="profile-content">
        <div class="page-content-header">
            <div class="page-content-body-container">

                <div class="row" id="jdenticon-profile-container">
                    <div class="profile-image" v-if="getUser">
                        <div class="jdenticon-container" v-if="!getUser.picture">
                            <svg width="40" height="40" :data-jdenticon-value="getUsername"></svg>

                        </div>
                        <img v-else :src="getUser.picture" alt="Profile Picture">
                    </div>
                    <p class="f600">{{ getUsername }}</p>

                </div>
            </div>
        </div>
        <!-- <p class="section-title">
                    My Profile
                </p> -->
        <div class="page-content-body">



            <div class="page-content-body-container">
                <div class="row space-btn">

                    <p class="section-subtitle">
                        Account Information
                    </p>

                </div>
                <div class="column account-detail">
                    <p class="label">Username</p>
                    <p class="f500" v-if="getUser">{{ getUser.username }}</p>
                </div>
                <div class="column account-detail">
                    <p class="label">Email Address</p>
                    <p class="f500" v-if="getUser">{{ getUser.email }}</p>
                </div>
            </div>
        </div>

        <div class="page-content-footer">
            <div class="center-container">

            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {

        ...mapGetters('user', ['getUsername', 'getUser']),
    }
}

</script>