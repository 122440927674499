<template>
    <BaseModal @close="$emit('close')">
        <template v-slot:header>
            <p class="sub-title">Deposit To Your Wallet</p>
        </template>

        <template v-slot:body>
            <div class="form-container">
                <label for="">Amount</label>
                <!-- <p class="caption">Select an amount</p> -->
                <div class="amount-chippers-selector">

                    <ChipperCard v-for="chipper in chipperAmounts" :key="chipper.id" :label="`KSH ${chipper.amount}`"
                        :selected="chipper.amount == selectedAmount" @click="selectedAmount = chipper.amount" />
                </div>
            </div>


            <hr class="divider amount-select">

            <div class="form-container">
                <label for=""></label>
                <div class="row">
                    <p class="currency-indicator">
                        KSH
                    </p>
                    <input type="number" class="small-input normal-input" placeholder="Amount" v-model="selectedAmount"
                        min="1">
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary-btn big-btn full-width-btn margin-top" id="deposit-amount-btn" @click="buttonClick" :disabled="!amountValidation">
                <div class="row">
                    Proceed to Payment Method
                    <i class="fas fa-chevron-right"></i>
                </div>
            </button>
        </template>
    </BaseModal>
</template>
<script>
import BaseModal from './BaseModal.vue';
import ChipperCard from '../ChipperCard.vue';
export default {
    components: {
        BaseModal,
        ChipperCard
    },
    computed: {
        amountValidation() {
            if (this.selectedAmount != null && this.selectedAmount > 0) {
                return true
            }
            return false
        },
    },
    data() {
        return {
            chipperAmounts: [
                {
                    id: 1,
                    amount: 50
                },
                {
                    id: 2,
                    amount: 100
                },
                {
                    id: 3,
                    amount: 150
                },
                {
                    id: 4,
                    amount: 200
                },
                {
                    id: 5,
                    amount: 500
                },
            ],
            selectedAmount: null
        }
    },
    methods: {

        buttonClick() {
            if(this.amountValidation){

                this.$store.commit('wallet/setAmountToDeposit', this.selectedAmount);
                this.$emit("amountSetComplete", this.selectedAmount)
            }
            else{
                this.$toast.warning("Please select an amount to deposit")
            }
        }
    }
}
</script>