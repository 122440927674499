<template>

    <div class="container fade-in-element" v-auto-animate>
        <div class="close-btn icon-btn" @click="closePanel">
            <i class="fas fa-times"></i>
        </div>
        <slot name="header"></slot>
    </div>
    <slot></slot>
    <slot name="body">

    </slot>

    <slot name="footer"></slot>

</template>

<script>
export default{
    props: {
        withdraw:{
            default: false,
            type: Boolean
        }
    },
    methods:{
        closePanel(){
            if(this.$props.withdraw){

                this.$store.commit('wallet/toggleWithdrawSideBar')
            }
            else{
                this.$store.commit('wallet/toggleDepositSideBar')
            }
        }
    }
}
</script>