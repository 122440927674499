<template>
    <StakeModal :stake_amount="stake" :stake_level="selected_stake" :game_level="getLevel" v-if="show_modal"
        @close="show_modal = false" @playGame="playGame" />
    <InfoContainer>
        <template v-slot:header>
            <img src="@/assets/logo.png" alt="N2 header" class="logo">
            <p id="header-caption">Want to put your money on it?</p>
        </template>

        <template v-slot:subheader>

            <h3 class='medium-header' id="difficulty-level-header">Place A Stake On Your Score</h3>
        </template>

        <template v-slot:body>
            <div class="difficulty-selector row">
                <DifficultyCard name="No Bet" :level="0" @click="selected_stake = 0" :selected="selected_stake === 0" />
                <DifficultyCard name="21 - 27" :level="1" @click="selected_stake = 1" :selected="selected_stake === 1" />
                <DifficultyCard name="27 - 35" :level="2" @click="selected_stake = 2" :selected="selected_stake === 2" />
                <DifficultyCard name="36 - 50" :level="3" @click="selected_stake = 3" :selected="selected_stake === 3" />
                <DifficultyCard name="50 - 60" :level="4" @click="selected_stake = 4" :selected="selected_stake === 4" />
            </div>
        </template>
        <template v-slot:footer>
            <div class="row space-btn" id="stake-container">
                <div class="form-container" id="stake-form-container" v-if="selected_stake != 0 && user" >
                    <label for="">Stake</label>
                    <div class="row" v-if="user">
                        <p>$</p>
                        <input type="number" v-model="stake">
                    </div>

                </div>

                <p class="info caption" v-if="!user && selected_stake!=0">Cannot place stake. <router-link :to="{ name: 'login' }">Login
                        First</router-link> </p>
                <p class="info caption" v-else>Playing a free one</p>
                <div class="row">
                    <LoadingButton class="primary-btn" @click="stakeSet" :loading="loading" :disabled="selected_stake!=0 && user==null">
                        <div class="row">
                            <p v-if="selected_stake != 0">Place Stake</p>
                            <p v-else>Play Game</p>
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>

                        </div>
                    </LoadingButton>
                </div>
            </div>
            <div class="error-container" v-if="error">
                <div class="error-text">

                    <p v-if="error.type === 'payment'">Insufficient Balance on Wallet. <router-link
                            :to="{ name: 'profile' }">Deposit
                            Now</router-link> </p>
                    <p v-else>{{ error.message }}</p>
                </div>
            </div>
        </template>

    </InfoContainer>
</template>
<script>
import InfoContainer from '@/components/InfoContainer.vue'
import DifficultyCard from '@/components/DifficultyCard.vue'
import StakeModal from '@/components/modal/StakeModal.vue'
import LoadingButton from '@/components/buttons/LoadingButton.vue'
import { mapGetters, mapState } from 'vuex'
import { useToast } from 'vue-toastification'

const toast = useToast()
export default {
    name: 'StakeSelection',
    data() {
        return {
            selected_stake: 0,
            stake: 1,
            show_modal: false,
            loading: false,
            error: null
        }
    },
    computed: {
        ...mapGetters('game', ['getLevel']),
        ...mapState('user', ['user'])
    },
    components: {
        InfoContainer,
        DifficultyCard,
        StakeModal, LoadingButton
    },
    methods: {

        stakeSet() {
            this.error = null
            if (!this.loading) {

                if (this.selected_stake != 0) {
                    if(this.user){

                        this .show_modal = true
                    }
                    else{
                        toast.error("You need to login to place a stake")
                    }

                }
                else {
                    this.playGame()
                }
            }
        },

        playGame() {
            this.loading = true
            this.$store.dispatch('game/setStake', { stake: this.selected_stake, amount: this.stake })
            this.$store.dispatch('game/gameEpisode').then(() => {
                this.$router.push({ name: 'game_play' })
                this.loading = false
            }).catch(e => {
                if (e.response) {

                    toast.error(e.response.data.error)
                    // let error = e.response.error
                    if (e.response.status === 402) {
                        this.error = {
                            type: "payment",
                            message: e.response.error
                        }
                    }
                    else {
                        this.error = {
                            type: "normal",
                            message: e.response.error
                        }
                    }
                }
                else {
                    toast.error("An error has occured. Check your connection")
                }
                //TODO: Do error handling modals and shit
                console.log(e)
            })
            this.loading = false
        }
    }
}
</script>