import router from "@/router";
import axios from "axios";
import { useToast } from "vue-toastification";
let baseURL = "https://api.n2game.co.ke/";
var toast = useToast();
export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    getUsername(state) {
      if (state.user != null) {
        return state.user.username;
      }
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async getUser({ commit }) {
      var token = localStorage.getItem("token");
      if (token != null && token !== "") {
        axios
          .get(`${baseURL}auth/user/`, {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            commit("setUser", res.data);
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status == 401) {
                toast.warning("You need to login");
                router.replace({ name: "login" });
              }
            }
          });
      }
    },
    async logout({ commit }) {
      localStorage.removeItem("token");
      commit("setUser", null);
      toast.success("You have been logged out");
      router.replace({ name: "login" });
    },
    async googleCallback({ dispatch }, e) {
      axios
        .post(`${baseURL}auth/google/`, e)
        .then((res) => {
          var token = res.data.key;
          localStorage.setItem("token", `Token ${token}`);
          dispatch("getUser").then(()=>{
            router.replace({name:"profile"})
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async login({ dispatch }, e) {
      return axios.post(`${baseURL}auth/login/`, e).then((res) => {
        var key = res.data.key;
        localStorage.setItem("token", `Token ${key}`);
        dispatch("getUser");
      });
    },

    async register(_, e) {
      return axios
        .post(`${baseURL}auth/register/`, e)
        .then((res) => {
          return res;
        })
    
    },
    sendResetPasswordEmail(_, email) {
      return axios.post(`${baseURL}auth/password/reset/`, { email: email });
    },
    resetPassword(_, e) {
      return axios.post(`${baseURL}auth/password/reset/confirm/`, e);
    },
  },
};
