import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import GameView from "../views/GameView.vue";
import ProfileView from "../views/ProfileView.vue";
import AuthView from "../views/AuthView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import RegisterEmailView from "@/views/auth/RegisterEmailView.vue";
import RegisterView from "@/views/auth/RegisterView.vue";
import ForgotPasswordEmailView from "@/views/auth/ForgotPasswordEmailView.vue";
import EmailVerifyView from "@/views/auth/EmailVerifyView.vue";
import ResetPasswordView from "@/views/auth/ResetPasswordView.vue";
import DifficultySelection from "@/views/game/DifficultySelection.vue"
import StakeSelection from "@/views/game/StakeSelection.vue"
import GamePlay from "@/views/game/GamePlay.vue"
import GameOver from "@/views/game/GameOver.vue"
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/game",
    name: "game",
    component: GameView,
    children:[
      {

        path:'',
        name:'game_difficulty',
        component:DifficultySelection
      },
      {
        path:"stake/",
        name:'game_stake',
        component:StakeSelection
      },
      {
        path: 'play/',
        name:'game_play',
        component: GamePlay
      },
      {
        path: 'over/',
        name: 'game_over',
        component: GameOver
      }

    ]
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
    children: [
      {
        path: "",
        redirect: { name: "login" },
      },
      {
        path: "login/",
        name: "login",
        component: LoginView,
      },
      {
        path: "register/",
        name: "register",
        component: RegisterView,
      },
      {
        path: "register/email/",
        name: "register-email",
        component: RegisterEmailView,
      },
      {
        path: "register/email/verify/",
        name: "register-email-verify",
        component: EmailVerifyView,
      },
      {
        path: "password_reset/new/",
        name: "password-reset-new",
        component: ForgotPasswordEmailView,
      },
      {
        path: "password_reset/:uid/:token/reset",
        name: "password-reset-code",
        component: ResetPasswordView,
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
