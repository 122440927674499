<template>
    <span class="countdown-element">
        {{ count }}
    </span>
</template>
<script>
export default {
    props: {
        number: {
            type: Number,
            required: true
        },
        delay: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            count: 0
        };
    },
    mounted() {
        this.startCountdown();
    },
    methods: {
        startCountdown() {
            setTimeout(() => {
                const interval = 100;
                const targetNumber = this.number;
                let currentNumber = 0;

                const countdownInterval = setInterval(() => {
                    if (currentNumber < targetNumber) {
                        currentNumber++;
                        this.count = currentNumber;
                    } else {
                        clearInterval(countdownInterval);
                    }
                }, interval);
            }, this.$props.delay)

        }
    }
};
</script>