<template>
   <nav>
      <router-link :to="{ name: 'home' }">
         <img src="@/assets/logo-white.png" class="logo">
      </router-link>


      <router-link :to="{ name: 'game_difficulty' }">
         <div class="nav-link">
            <svg class='nav-icon' viewBox="0 0 60 59.996">
               <g id="game" transform="translate(-2 -2)">
                  <path id="Path_14" data-name="Path 14"
                     d="M45.95,5.719H18.05A16.068,16.068,0,0,0,2,21.769v16.74a16.068,16.068,0,0,0,16.05,16.05h27.9A16.068,16.068,0,0,0,62,38.509V21.769A16.068,16.068,0,0,0,45.95,5.719Zm-6.45,18.9a3,3,0,1,1,0,.06ZM26.39,36.769a2.245,2.245,0,0,1-3.18,0l-3.09-3.09-2.97,2.97a2.245,2.245,0,0,1-3.18,0,2.263,2.263,0,0,1,0-3.18l2.97-2.97-2.88-2.88a2.249,2.249,0,0,1,3.18-3.18l2.88,2.88,2.97-2.97a2.248,2.248,0,1,1,3.18,3.18L23.3,30.5l3.09,3.09A2.263,2.263,0,0,1,26.39,36.769Zm10.23-3.21a3.035,3.035,0,0,1-3.03-3,2.983,2.983,0,0,1,2.97-3h.06a3,3,0,0,1,0,6Zm5.88,5.91a2.983,2.983,0,0,1-3-2.97v-.06a3,3,0,1,1,3,3.03Zm5.94-5.91a3.035,3.035,0,0,1-3.03-3,2.983,2.983,0,0,1,2.97-3h.06a3,3,0,1,1,0,6Z"
                     transform="translate(0 7.438)" />
                  <path id="Path_15" data-name="Path 15"
                     d="M20.2,4.13l-.03,2.82a4.918,4.918,0,0,1-4.89,4.83.67.67,0,0,0-.72.69.679.679,0,0,0,.69.69h-4.83a4.819,4.819,0,0,1,4.8-5.58.7.7,0,0,0,.75-.69L16,4.07A2.12,2.12,0,0,1,18.1,2h.03A2.088,2.088,0,0,1,20.2,4.13Z"
                     transform="translate(16.719)" />
               </g>
            </svg>

         </div>
      </router-link>
      <div class="nav-links">
         <router-link :to="{ name: 'profile' }">
            <div class="nav-link">
               <svg class="nav-icon" viewBox="0 0 44.074 65.834">
                  <g id="person" transform="translate(-84.617)">
                     <path id="Path_7" data-name="Path 7"
                        d="M140.413,34.114a17.057,17.057,0,1,0-17.056-17.057A17.057,17.057,0,0,0,140.413,34.114Z"
                        transform="translate(-33.759)" />
                     <path id="Path_8" data-name="Path 8"
                        d="M128.5,296.074c-.761-4.561-4.629-10.866-7.4-13.818a2.171,2.171,0,0,0-2.508-.184,22.679,22.679,0,0,1-23.87,0,2.172,2.172,0,0,0-2.508.184c-2.771,2.952-6.638,9.257-7.4,13.818-1.871,11.23,10.126,15.288,21.843,15.288S130.368,307.3,128.5,296.074Z"
                        transform="translate(0 -245.527)" />
                  </g>
               </svg>
            </div>
         </router-link>
        
         <!-- <router-link to="/">
            <div class="nav-link">
               <svg class='nav-icon' viewBox="0 0 60.021 61.474">
                  <g id="wallet-1-svgrepo-com" transform="translate(-2.238 -2.008)">
                     <path id="Path_9" data-name="Path 9"
                        d="M24.367,2.635l-7.41,17.25H9.547a17.409,17.409,0,0,0-3.6.338l3.075-7.38.123-.277.184-.492a4.877,4.877,0,0,1,.246-.553C13.144,3.25,17.142.667,24.367,2.635Z"
                        transform="translate(7.7 0)" />
                     <path id="Path_10" data-name="Path 10"
                        d="M35.558,19.646l-.061-.031a20.562,20.562,0,0,0-5.627-.8H10.621L17.539,2.735l.092-.215c.43.154.892.369,1.353.523l6.8,2.86c3.782,1.568,6.426,3.2,8.056,5.166.277.369.523.707.769,1.107A4.678,4.678,0,0,1,35.22,13.5a4.115,4.115,0,0,1,.277.8A10.776,10.776,0,0,1,35.558,19.646Z"
                        transform="translate(17.393 1.062)" />
                     <path id="Path_11" data-name="Path 11"
                        d="M12.519,20.418h.769a1.783,1.783,0,0,0,1.691-1.845c0-1.291-.369-1.476-1.168-1.783l-1.291-.461Z"
                        transform="translate(21.332 29.712)" />
                     <path id="Path_12" data-name="Path 12"
                        d="M51.589,9.935a15.55,15.55,0,0,0-4.336-.615H17.243a14.687,14.687,0,0,0-5.9,1.2,15.047,15.047,0,0,0-9.1,13.806v6A17.368,17.368,0,0,0,2.392,32.5Q3.407,47.171,18.074,48.155a16.585,16.585,0,0,0,2.183.154H44.241c11.377,0,17.373-5.412,17.957-16.174.031-.584.061-1.2.061-1.814v-6A15.066,15.066,0,0,0,51.589,9.935Zm-15.4,18.387a4.542,4.542,0,0,1,3.321,4.8,4.956,4.956,0,0,1-4.889,5.043h-.769v.676a1.6,1.6,0,1,1-3.2,0v-.676h-.277A5.488,5.488,0,0,1,24.992,32.6a1.6,1.6,0,0,1,3.2,0,2.3,2.3,0,0,0,2.183,2.368h.277v-5.2l-2.337-.83a4.543,4.543,0,0,1-3.321-4.8A4.956,4.956,0,0,1,29.881,19.1h.769v-.676a1.6,1.6,0,1,1,3.2,0V19.1h.277a5.488,5.488,0,0,1,5.381,5.565,1.6,1.6,0,1,1-3.2,0A2.3,2.3,0,0,0,34.124,22.3h-.277v5.2Z"
                        transform="translate(0 15.172)" />
                     <path id="Path_13" data-name="Path 13"
                        d="M10.68,15.384c0,1.291.369,1.476,1.168,1.783l1.291.461v-4.09h-.769A1.763,1.763,0,0,0,10.68,15.384Z"
                        transform="translate(17.515 23.925)" />
                  </g>
               </svg>
            </div>
         </router-link> -->
      </div>


   </nav>
</template>

<script>
const gameIcon = require("@/assets/icons/game.svg");
const personIcon = require("@/assets/icons/person.svg");
const walletIcon = require("@/assets/icons/wallet.svg");
const faqIcon = require("@/assets/icons/faq.svg");
export default {
   name: "nav-bar",
   data() {
      return {
         gameIcon,
         personIcon,
         walletIcon,
         faqIcon
      };
   }

}

</script>