<template>
    <WalletPanel>

        <template v-slot:header>
            <div name="header" class="panel-header">
                <p class="sub-title">Pay By Credit or Debit Card</p>
            </div>
        </template>

        <template v-slot:body>
            <div class="center-container">

                <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="45" color="grey" v-if="loading" />
            </div>

            <StripeCheckout @loading="v => loading = v" v-if="!loading" />
        </template>

        <template v-slot:footer>
            <div class="panel-footer">
                <p class="deposit-panel-amount">Amount: <span>Ksh {{ amount_to_deposit }}</span></p>
                <button class="primary-btn big-btn full-width-btn" @click="paymentClick" :disabled="selectedMethod === null">
                    <div class="row">
                        Proceed to Payment
                    </div>
                </button>
            </div>
        </template>

    </WalletPanel>
</template>

<script>
import { mapState } from 'vuex';
import WalletPanel from './WalletPanel.vue';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'

export default {
    components: {
        WalletPanel, StripeCheckout, FulfillingBouncingCircleSpinner
    },
    data() {
        return {
            loading: true
        }
    },
    computed: {
        ...mapState('wallet', ['amount_to_deposit'])
    }
}
</script>