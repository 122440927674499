<template>
 
  
  <Navbar />
  <main class="content">

    <router-view />
  </main>

  <!-- <div class="panel-sidebar" v-if="isSideBarOpen">
  </div> -->
</template>

<script>
import { mapState } from 'vuex'
import Navbar from './components/navigation/NavBar.vue'
import { googleOneTap } from "vue3-google-login"

export default {
  components: {
    Navbar
  },
  computed:{
    ...mapState(['isSideBarOpen'])
  },

  mounted() {
    this.$store.dispatch('user/getUser')
    googleOneTap({prompt: true })
      .then((response) => {
        // This promise is resolved when user selects an account from the the One Tap prompt
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
        // This promise is rejected when user cancels the One Tap prompt
        console.log("Handle the error", error)
      })
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
</style>
