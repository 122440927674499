<template>
    <DepositPanel v-if="isDepositSideBarOpen" />
    <WithdrawPanel v-if="isWithdrawSideBarOpen" />

    <DepositModal v-if="showDepositModal" @amountSetComplete="amountSetComplete" @close="showDepositModal = false" />
    <WithdrawModal v-if="showWithdrawModal" @close="showWithdrawModal = false"
        @amountSetComplete="withdrawAmountSetComplete" />
    <section class="page-content" id="wallet-content">
        <!-- <p class="section-title">
            My Wallet
        </p> -->
        <div class="page-content-body">
            <div class="page-content-body-container" id="wallet-details-body">
                <div class="column">

                    <div class="center-container">

                        <p class="wallet-balance">
                            <span>KES </span>{{ balance }}.00
                        </p>
                    </div>
                </div>
                <div class="center-container">

                    <div class="row" style="gap: 15px;">
                        <button class="primary-btn" id="deposit-btn" @click="showDepositModal = true">Deposit</button>
                        <button class="primary-btn" id="withdraw-btn" @click="showWithdrawModal = true">Withdraw</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-content-body">
            <div id="wallet-transactions">
                <p class="small-subtitle">Transcations</p>

                <div class="transaction-list">
                    <TransactionCard v-for="transaction in transactions" :key="transaction.uuid" :type="transaction.type"
                        :amount="transaction.amount" :datetime="transaction.date_made" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TransactionCard from '@/components/profile/TransactionCard.vue'
import DepositPanel from '../panels/DepositPanel.vue'
import WithdrawModal from '../modal/WithdrawModal.vue'
import WithdrawPanel from '../panels/WithdrawPanel.vue'
import DepositModal from '../modal/DepositModal.vue'
import { mapState } from 'vuex'
export default {
    name: 'WalletSection',
    components: {
        TransactionCard, DepositPanel, DepositModal, WithdrawModal, WithdrawPanel
    },

    data() {
        return {
            showDepositModal: false,
            // showDepositPanel: false,
            // showWithdrawPanel: false,
            showWithdrawModal: false
        }
    },
    computed: {
        ...mapState('wallet', ['balance', 'transactions', 'isDepositSideBarOpen', 'isWithdrawSideBarOpen']),
    },

    methods: {
        amountSetComplete() {
            this.showDepositModal = false;
            this.$store.commit('wallet/toggleDepositSideBar')
        },
        withdrawAmountSetComplete() {
            this.showWithdrawModal = false;
            this.$store.commit('wallet/toggleWithdrawSideBar')
        }
    },

    mounted() {
        this.$store.dispatch('wallet/initialize')
    }
}

</script>