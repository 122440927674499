<template>
    <WalletPanel :withdraw="withdraw">
        <template v-slot:header>
            <div name="header" class="panel-header">
                <p class="sub-title" v-if="!withdraw">Choose a payment method</p>
                <p class="sub-title" v-else>Withdraw to:</p>
            </div>
        </template>
        <template v-slot:body>

            <div class="panel-body">

                <div class="payment-methods-list">
                    <PaymentMethodCard name="MPESA" description="Send from your mobile phone" image="" :selected="selectedMethod=='mpesa'" @click="selectedMethod='mpesa'"/>
                    <PaymentMethodCard :selected="selectedMethod==='credit'" @click="disabledMethodClick" aria-disabled="true" :disabled="true"/>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="panel-footer">
                <p class="deposit-panel-amount" v-if="!withdraw">Amount: <span>Ksh {{ amount_to_deposit }}</span></p>
                <p class="deposit-panel-amount" v-else>Amount: <span>Ksh {{ amount_to_withdraw }}</span></p>
                <button class="primary-btn big-btn full-width-btn" @click="paymentClick" :disabled="selectedMethod===null">
                    <div class="row">
                        Proceed to Payment
                    </div>
                </button>
            </div>
        </template>

    </WalletPanel>
</template>
<script>
import PaymentMethodCard from '@/components/PaymentMethodCard.vue'
import WalletPanel from './WalletPanel.vue';
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';
var toast = useToast()
export default {
    props:{
        withdraw: {
            default: false,
            type: Boolean
        }
    },
    components: {
        PaymentMethodCard, WalletPanel
    },
    computed:{
        ...mapState('wallet',['amount_to_deposit','amount_to_withdraw'])
    },
    data(){
        return {
        
            selectedMethod: null
        }
    },
    methods:{
        paymentClick(){
            if(this.selectedMethod!=null){

                this.$emit("methodSelected",this.selectedMethod)
            }
        },
        disabledMethodClick(){
            toast.warning("Coming Soon. Credit card payment will be available later")
        }
    }
}

</script>