<template>
    <div class="game-area" v-if="isGameReady">
        <GameLine v-for="circles, index in gameCircles" :key="index" :circles="circles" @lineComplete="nextLine" />
    </div>

    <div class="game-area" v-else>
        <div class="game-area-loading">
            <SwappingSquaresSpinner color="#ff1d5e" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { SwappingSquaresSpinner } from 'epic-spinners'
import GameLine from './GameLine.vue'

export default {

    components: {
        GameLine,
        SwappingSquaresSpinner,
    },

    computed: {
        ...mapGetters('game', ['getGameData', 'getLevel']),
        isGameReady() {
            return this.gameCircles.length > 0
        },

        bunchCount() {
            return this.getGameData.length / this.bunch
        },

    },
    name: 'GameArea',
    data() {

        return {
            missedCount: 0,
            wrongCount: 0,
            correctCount: 0,
            bunch: 5,
            counter: 0,

            gameCircles: [],
        }
    },


    methods: {

        removeCircle(id) {

            setTimeout(() => {
                this.gameCircles = this.gameCircles.filter(e => e.id != id);

            }, 500)

        },

        randomInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        nextLine() {            
            if (this.counter < this.getGameData.length) {

                const sliceStart = this.counter;
                const sliceEnd = this.counter + this.bunch;
                const itemsToAdd = this.getGameData.slice(sliceStart, sliceEnd);
                this.gameCircles.push(itemsToAdd)
                this.counter += this.bunch

            }
        }



    },



    mounted() {
        // push an element to gameCircles from props every second


        this.nextLine()



    }


}
</script>