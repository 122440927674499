<template>
    <InfoContainer id="game-over-container" v-if="game_over_data">
        <template v-slot:header>
            <img src="@/assets/logo.png" alt="N2 header" class="logo">
            <h3 class='medium-header' id="difficulty-level-header">Game Over</h3>

        </template>

        <template v-slot:subheader>
            <div class="center-container">

                <ScoreArc :percentage="getPercentage" :value="getScore"/>
            </div>
        </template>

        <template v-slot:body>

            <div class="center-container">

                <div class="row" id="final-score-container">

                    <p class="game-score">Missed
                        <CountdownText :number="getMissed" />
                    </p>
                    <p class="game-score">Correct
                        <CountdownText :number="getCorrect" />
                    </p>
                    <p class="game-score">Wrong
                        <CountdownText :number="getWrong" />
                    </p>
                </div>



            </div>



        </template>
        <template v-slot:footer>

            <button class="primary-btn" @click="startGame" v-if="game_over_data">
                <div class="row">
                    Start New Game
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>

                </div>

            </button>
            <div class="container footer-info-text success-container" v-if="payoutMade">
                <div class="container">

                    <p class="payout-text">A payout of <span>${{ game_over_data.payout }}</span> has been made to your
                        account.
                        <router-link :to="{ name: 'profile' }">View Wallet</router-link>
                    </p>
                </div>
            </div>
        </template>

    </InfoContainer>
    <div class="center-container" v-else>
        <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" color="white" />

    </div>
</template>
<script>

import InfoContainer from '@/components/InfoContainer.vue'
import ScoreArc from '@/components/game/ScoreArc.vue';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
import CountdownText from '@/components/CountdownText.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'GameOver',
    computed: {
        ...mapGetters('game', ['getScore', 'getStake', 'getCorrect', 'getMissed', 'getTotal', 'getWrong']),
        ...mapState('game', ['game_over_data']),

        getPercentage() {
            return (this.getScore / this.getTotal) * 100
        },
        payoutMade() {
            return this.game_over_data.payout && this.game_over_data.payout > 0
        }
    },
    methods: {
        startGame() {
            this.$router.replace({ name: 'game_difficulty' })
            this.$store.dispatch('game/clearState')
        }
    },

    components: {
        InfoContainer, ScoreArc, FulfillingBouncingCircleSpinner, CountdownText
    },
    // methods:{
    //     difficultySet(){
    //         this.$emit('difficultySet', this.selected_level)
    //     }
    // }
}
</script>