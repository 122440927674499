<template>
    <div class="transaction-card">
        <div class="transaction-indicator" :class="transactionClass">

        </div>

        <div class="column">
            <div class="row space-btn">
                <p class="transaction-type">{{ type }}</p>
                <p class="transaction-amount" :class="transactionClass">KES {{ amount }}</p>
            </div>
            <div class="row space-btn">
                <p class="transaction-date">{{ date }}</p>
                <p class="transaction-date">{{ time }}</p>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        type: {
            required: true
        },
        amount: {
            required: true
        },
        datetime: {
            required: true
        },
        
    },
    computed: {
        //css classes from the type prop

        date(){
            
            return this.$moment(this.$props.datetime).format("MMM D YYYY")
        },
        time(){
            
            return this.$moment(this.$props.datetime).format("h:mm a")
        },
        transactionClass() {

            let type = this.$props.type

            if (type === "Deposit") {
                return "transaction-deposit"
            }
            else if (type === "Withdraw") {
                return "transaction-withdraw"
            }
            else if (type === "Bet") {
                return "transaction-bet"
            }
            else {
                return "transaction-neutral"
            }
        }

    }
}
</script>