<template>
  <title>N2 | Home</title>
  <div class="home-page n2-page">
    <div class="home-hero">

    </div>
    <div class="home-header">
      <!-- <TypedText class="welcome-title" text="Welcome to N2: Your Ultimate Arithmetic Challenge"></TypedText> -->
      <p class="welcome-title">Welcome to <span class="fade-in-element-long">N2</span>: Bet on Your Brilliance</p>
      <p class="welcome-description">Are you ready to elevate your <span>math</span> skills while adding a thrilling
        twist? Welcome to
        N2, where you can put your math prowess to the test and <span>bet</span> on your score for some extra excitement!
      </p>
      <div class="center-container margin-top">

        <button class="hero-btn" @click="$router.push({name:'game_difficulty'})">
          <div class="row">Play a Game

            <i class="fas fa-arrow-right"></i>
          </div>
        </button>
      </div>
    </div>
    <div class="home-body">
      <section id="home-features">

        <p class="section-subtitle">What you will love</p>
        <div class="home-features-list">
          
          <FeatureCard title="Bet On Your Score" description="Add excitement with strategic bets and triumph.">
            <template v-slot:icon>
              <i class="fas fa-coins"></i>
            </template>
          </FeatureCard>
          <FeatureCard title="Withdraw Your Winnings" description="Turn Success into Reward.">
            <template v-slot:icon>
              <i class="fas fa-money-bill"></i>
            </template>
          </FeatureCard>
          <FeatureCard title="Varied Difficulty Levels" description="Tailored Challenges for Every Skill Level.">
            <template v-slot:icon>
              <i class="fas fa-level-up-alt"></i>
            </template>
          </FeatureCard>
          <FeatureCard title="Fast Paced" description="Rapid math challenges in a frenzy.">
            <template v-slot:icon>
              <i class="fas fa-rocket"></i>
            </template>
          </FeatureCard>

        </div>
      </section>

    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
import FeatureCard from '@/components/FeatureCard.vue';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'HomeView',
  components: {
    FeatureCard
  }
}
</script>
