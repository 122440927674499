<template>
    <div class="difficulty-card row" :class="{difficulty_card_selected:selected}">
        
        <div class="badge" :class="levelStying">
            {{ level }}
        </div>
        <p>{{ levelName }}</p>
    </div>
</template>

<script>
export default {
    name: 'DifficultyCard',
    props:{
        level:{
            default:1,
            required:true
        },
        selected: {
           default:false, 
        },
        name:{
            required: false
        }
    },
    computed:{
        levelStying(){
            let level = this.$props.level;
            let selected = this.$props.selected;
            if(level===0){
                if(selected){
                    return "no-level-selected"
                }
                return "no-level"
            }
            if(level===1){
                if(selected){
                    return "easy-level-selected";
                }
                return 'easy-level';
            }
            else if(level===2){
                if(selected){
                    return "easy-medium-level-selected";
                }
                return 'easy-medium-level';
            }
            else if(level===3){
                if(selected){
                    return "medium-level-selected";
                }
                return "medium-level";
                
            }
            else{
                if(selected){
                    return "hard-level-selected";
                }
                return "hard-level"
            }
        },
        levelName(){
            if(this.$props.name!=null){
                return this.$props.name;
            }
            let level = this.$props.level;
            if(level===1){
                return 'Easy';
            }
            else if(level===2){
                return 'Medium';
            }
            else if(level===3){
                return "Hard";
            }
            else{
                return "Impossible"
            }
        }
    }
    
}
</script>