import api from "@/utils/axios_util";
import { useToast } from "vue-toastification";
var toast = useToast();
export default {
  namespaced: true,
  state: {
    level: 1,
    stake: 1,
    staked_amount: 0,
    missed: 0,
    correct: 0,
    wrong: 0,
    completed_circles: 0,
    game_data: [],
    session_id: null,
    game_hash: null,
    game_over_data: null,
  },
  mutations: {
    setLevel(state, level) {
      state.level = level;
    },
    setStake(state, stake) {
      state.stake = stake;
    },
    setStakeAmount(state, amount) {
      state.staked_amount = amount;
    },
    setMissed(state) {
      state.missed += 1;
    },
    setCorrect(state) {
      state.correct += 1;
    },
    setWrong(state) {
      state.wrong += 1;
    },
    setSessionId(state, id) {
      state.session_id = id;
    },
    setSessionHash(state, hash) {
      state.game_hash = hash;
    },
    setGameOverData(state, data) {
      state.game_over_data = data;
    },
    setGameData(state, data) {
      state.game_data = data;
    },
    addCompleteCircle(state) {
      state.completed_circles += 1;
    },
    clearState(state) {
      state.level = 1;
      state.stake = 0;
      state.staked_amount = 0;
      state.missed = 0;
      state.correct = 0;
      state.wrong = 0;
      state.game_data = [];
      state.completed_circles = 0;
      state.game_over_data = null;
    },
  },
  getters: {
    getLevel(state) {
      return state.level;
    },
    getGameSpeed(state) {
      if (state.level === 1) {
        return 1*3;
      } else if (state.level === 2) {
        return 2*3
      } else if (state.level === 3) {
        return 2.75*3
      } else {
        return 3.2*3
      }
    },

    getCircleIntervalTime(state) {
      if (state.level === 1) {
        return 3000/2.5;
      } else if (state.level === 2) {
        return 2000/2.5;
      } else if (state.level === 3) {
        return 1500/2.5;
      } else {
        return 1000/2.5;
      }
    },
    getLineIntervalTime(state) {
      if (state.level === 1) {
        return 1500/1.6;
      } else if (state.level === 2) {
        return 1200/1.6;
      } else if (state.level === 3) {
        return 750/1.6;
      } else {
        return 500/1.6;
      }
    },

    getSpawnTime(state) {
      if (state.level === 1) {
        return 4700/1.8;
      } else if (state.level === 2) {
        return 3500/1.8;
      } else if (state.level === 3) {
        return 2500/1.8;
      } else {
        return 1800/1.8;
      }
    },
    getScore(state) {
      let score = state.correct - (state.wrong + state.missed * 0.5);
      return Math.floor(score);
    },
    getMissed(state) {
      return state.missed;
    },
    getCorrect(state) {
      return state.correct;
    },
    getWrong(state) {
      return state.wrong;
    },
    getMoney(state) {
      return state.level * state.staked_amount;
    },
    getGameData(state) {
      return state.game_data;
    },
    getTotal(state) {
      return state.correct + state.wrong + state.missed;
    },
    getCompletedCircles(state) {
      return state.completed_circles;
    },
    isGameOver(state) {
      if (state.getTotal == 60) {
        return true;
      } else {
        return false;
      }
    },
  },
  actions: {
    // vuex actions to implement all the mutations
    setLevel({ commit }, level) {
      commit("setLevel", level);
    },
    setStake({ commit }, { stake, amount }) {
      console.log(stake, amount);
      commit("setStake", stake);
      commit("setStakeAmount", amount);
    },
    setMissed({ commit }) {
      commit("setMissed");
    },
    setCorrect({ commit }) {
      commit("setCorrect");
    },
    setWrong({ commit }) {
      commit("setWrong");
    },
    addCircle({ commit }, circle) {
      commit("addCompleteCircle", circle);
    },
    async gameEpisode({ commit, state }) {
      var res = await api.get("episode", {
        params: {
          game_level: state.level,
          stake_level: state.stake,
          staked_amount: state.staked_amount,
        },
      });
      var data = res.data;
      var session_id = data["session_id"];
      var session_hash = data["hash"];
      commit("setSessionId", session_id);
      commit("setSessionHash", session_hash);
      var sequence = data["seq"];

      var _gamedata = [];

      sequence.forEach((element, index) => {
        var game_circle = {
          id: index,
          number1: element["s"],
          number2: element["f"],
          operation: element["op"],
          assumed_answer: element["c"],
        };
        _gamedata.push(game_circle);
      });
      commit("setGameData", _gamedata);
      return _gamedata;
    },
    async getPossiblePayout(_, { game_level, bet_level, amount }) {
      try {
        var res = await api.get("wallet/possible_payout", {
          params: {
            game_level: game_level,
            bet_level: bet_level,
            amount: amount,
          },
        });

        return res.data.payout;
      } catch (e) {
        return 0;
      }
    },
    async gameOver({ commit, state }) {
      var res = await api.post(`episode/complete/${state.session_id}/`, {
        missed: state.missed,
        correct: state.correct,
        wrong: state.wrong,
        game_hash: state.game_hash,
      });
      commit("setGameOverData", res.data);
      if (res.data.payout && res.data.payout > 0.0) {
        toast.success("A payout has been made to your account :)");
      }
      return res.data;
    },
    setGameData({ commit }, data) {
      commit("setGameData", data);
    },
    clearState({ commit }) {
      commit("clearState");
    },
  },
};
