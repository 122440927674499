<template>
    <div class="game-group">


        <GameDetails status="true" />

        <GameArea @gameOver="gameOver" />
    </div>
</template>

<script>
import GameDetails from '@/components/game/GameDetails.vue';
import GameArea from '@/components/game/GameArea.vue'
import { mapState } from 'vuex';
export default{
    methods:{
        gameOver(){}
    },
    components:{
        GameDetails,
        GameArea
    },
    computed:{
        ...mapState('game',['game_data'])
    },
    mounted(){
        if(this.game_data.length==0){
            this.$router.replace({name:'game_stake'})
        }
        
    }
}
</script>