<template>
    <div class="payment-method-card card" :class="{selected:selected,disabled:disabled}">
        <div class="container row">

            <input type="radio" disabled :checked="selected">
            <div class="payment-method-details">
                <p class="payment-method-name">{{ name }}</p>
                <p class="payment-method-description caption">{{ description }}</p>
            </div>
        </div>
        <div class="payment-method-image">
            <img :src="image" alt="">
        </div>


    </div>
</template>
<script>
export default{
    props:{
        name:{
            type:String,
            default:'Credit or Debit Card'
        },
        description:{
            type:String,
            default:'Visa, Mastercard, Discover, American Express'
        },
        image:{
            type:String,
            default:'@/assets/credit_cards.png'
        },
        selected:{
            type: Boolean,
            default: false
        },
        disabled:{
            type: Boolean,
            default:false
        }
    }
}
</script>