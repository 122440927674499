<template>
    <div class="game-level-container info-container slide-in-right-element">
        <div class="info-container-header">
            <slot name="header"></slot>

        </div>
        <div class="info-container-subheader">
            <slot name="subheader"></slot>

        </div>
        <div class="info-container-body">
            <slot name="body"></slot>

        </div>
        <div class="info-container-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>