<template>
    <BaseModal>
        <template v-slot:header>
            <p class="sub-title">Do You Want To Logout</p>
        </template>
        <template v-slot:body>
            <div class="center-container margin-top">

                <div class="row" style="gap:40px">
                    <div class="text-btn" @click="$emit('close')">
                        <p id="modal-cancel">Cancel</p>
                    </div>
                    <button class="primary-btn error" @click="logout">Logout</button>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
export default {
    components: { BaseModal },
    methods: {
        logout() {
            this.$emit("logout")
           
        }
    }
}
</script>