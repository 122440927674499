<template>
    <WalletPanel :withdraw="withdraw">
        <template v-slot:header>
            <div name="header" class="panel-header">
                <p class="sub-title" v-if="!withdraw">Pay By M-PESA</p>
                <p class="sub-title" v-else>Withdraw To Mpesa</p>
            </div>
        </template>
        <template v-slot:body>

            <div class="panel-body" id="mpesa-payment-body">
                <div class="panel-body-header">
                    <div class="center-container">

                        <img src="@/assets/lipa_na_mpesa.png" alt="Mpesa Image" class="medium-image">
                    </div>
                </div>
                <div class="form-container">
                    <label for="phone">Enter Your Phone Number</label>
                    <input type="number" name="" id="" class="normal-input" placeholder="07xxxxxxxx" v-model="phone">
                </div>
                <p class="caption">
                    * Transactional charges may apply.
                </p>
                <p class="caption" v-if="!withdraw">* Enter your M-Pesa Pin when your receive a prompt on your phone to
                    complete your deposit
                </p>
                <p class="caption" v-if="!withdraw">* We do not store your Pin or phone number to ensure maximum security
                </p>
            </div>
        </template>
        <template v-slot:footer>
            <div class="panel-footer">
                <LoadingButton id="mpesa-btn" class="primary-btn big-btn full-width-btn" @click="buttonClick"
                    :loading="loading">
                    <div class="row">
                        <span v-if="withdraw">

                            Withdraw Ksh {{ amount_to_withdraw }} by M-Pesa
                        </span>
                        <span v-else>

                            Pay Ksh {{ amount_to_deposit }} by M-Pesa
                        </span>
                    </div>
                </LoadingButton>
            </div>
        </template>
    </WalletPanel>
</template>

<script>
import WalletPanel from './WalletPanel.vue';
import LoadingButton from '../buttons/LoadingButton.vue';
import { mapState } from 'vuex';
import { useToast } from 'vue-toastification';

var toast = useToast()
export default {
    props: {
        withdraw: {
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            phone: '',
            loading: false,
            successful: false
        }
    },
    computed: {
        ...mapState('wallet', ['amount_to_deposit', 'amount_to_withdraw']),
        isPhoneNumberValid() {
            var phone = this.phone.toString()
            return phone.length == 9 && (phone.startsWith('7') || phone.startsWith('1'))
        }
    },
    methods: {

        buttonClick() {

            if (this.$props.withdraw) {
                this.mpesaWithdraw()
            }
            else {
                this.mpesaPay()
            }
        },
        mpesaPay() {

            if (this.isPhoneNumberValid) {
                this.loading = true;
                this.$store.dispatch('wallet/lipaNaMpesa', { phone: `0${this.phone}` }).then((res) => {
                    if (res && res.status <= 210) {


                        var tries = 0;
                        var intervalID = setInterval(() => {
                            this.$store.dispatch('wallet/checkMpesaPayment').then((response) => {
                                if (response.status == 200) {
                                    clearInterval(intervalID)
                                    this.loading = false
                                    this.successful = true
                                    toast.success("Deposit was successful")
                                    this.$store.commit('toggleSideBar')
                                    return;
                                }


                            }).catch(e => {
                                if (e.response) {
                                    if (e.response.status != 425) {
                                        clearInterval(intervalID)
                                        toast.error("Deposit was unsuccessful try again")
                                        this.loading = false
                                    }
                                    else {
                                        tries++;
                                        if (tries > 6) {
                                            clearInterval(intervalID)
                                            toast.error("Deposit was unsuccessful try again")
                                            this.loading = false
                                        }
                                    }
                                }

                            })
                        }, 5000)
                    }
                    else {
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        mpesaWithdraw() {
            if (this.isPhoneNumberValid) {
                this.loading = true;
                this.$store.dispatch('wallet/withdrawToMpesa', { phone: `0${this.phone}` }).then((e => {
                    this.loading = false
                    if (e.status == 200) {

                        this.$store.commit('toggleSideBar')
                    }
                })).catch(e => {
                    console.log(e)
                    this.loading = false
                })
            }

        }
    },
    components: {
        WalletPanel, LoadingButton
    }
}
</script>