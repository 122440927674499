<template>
    <div class="auth-view">
        <div class="auth-container">
            <img src="@/assets/logo.png" alt="" class="auth-image-header">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

export default {

    
}
</script>