<template>
    <LogoutModal v-if="logoutModalShow" @close="logoutModalShow = false" @logout="logout" />
    <div class="profile-page n2-page">

        <div class="row space-btn" id="screen-profile-title">
            <p class="page-title">Account & Wallet</p>
            <button class="primary-btn danger" id="logout-btn" @click="logoutModalShow = true">
                <div class="row">
                    Logout
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                </div>
            </button>
        </div>
        <div class="page-content-container" id="account-page-container" v-if="user">
            <ProfileSection />
            <WalletSection />
        </div>
        <div class="center-container" v-else>
            <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" color="white" v-if="loading" />

        </div>
    </div>
</template>

<script>
import WalletSection from '@/components/profile/WalletSection.vue'
import ProfileSection from '@/components/profile/ProfileSection.vue'
import LogoutModal from '@/components/modal/LogoutModal.vue';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'

import { mapGetters, mapState } from 'vuex';
import { useToast } from 'vue-toastification';

var toast = useToast()
export default {
    name: "ProfileView",
    components: {
        WalletSection, ProfileSection, FulfillingBouncingCircleSpinner, LogoutModal
    },
    data() {
        return {
            loading: true,
            logoutModalShow: false
        }
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('user', ['getUsername']),

    },
    methods: {
        logout() {
            this.logoutModalShow = false
            
            toast.success("You have been logged out");
            localStorage.removeItem("token");
            this.$store.commit('user/setUser', null)
            this.$router.push({ name: "login" });
        }
    },
    mounted() {
        var token = localStorage.getItem("token")
        if (token === null || token == "") {
            this.$router.push({ name: 'login' })

        }

    }

}


</script>