<template>
    <div class="game-line">
        <GameCircle v-for="circle in gameCircles" :key="circle.id" :assumed_answer="circle.assumed_answer"
            :number1="circle.number1" :number2="circle.number2" :id="circle.id" :operation="circle.operation" :x="circle.x"
            @outOfScreen="removeCircle" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import GameCircle from './GameCircle.vue'
export default {

    components: {
        GameCircle
    },

    data() {
        return {
            gameCircles: []
        }
    },
    computed: {
        ...mapGetters('game', ['getLineIntervalTime', 'getSpawnTime'])
    },

    props: {
        circles: {
            type: Array,
            required: true
        }
    },

    methods: {
        removeCircle(id) {

            setTimeout(() => {
                this.gameCircles = this.gameCircles.filter(e => e.id != id);

            }, 500)

            this.$store.dispatch('game/addCircle', id)

        },
        pushCircle(index) {
            this.gameCircles.push(this.$props.circles[index])
        },
        shuffledIndexes() {
            //function to return an array of [1,2,3,4,5,6,] but shuffled randomly
            var number = this.$props.circles.length
            const originalArray = Array.from({ length: number }, (_, index) => index);

            // Fisher-Yates shuffle algorithm
            for (let i = originalArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [originalArray[i], originalArray[j]] = [originalArray[j], originalArray[i]];
            }

            return originalArray;
        }
    },

    mounted() {
        var circles = this.$props.circles
        var randomIndexes = this.shuffledIndexes()
        let _index = randomIndexes[0]
        circles[_index]['x'] = _index
        this.gameCircles.push(circles[randomIndexes[0]])
        var counter = 1
        let interval = setInterval(() => {
            if (counter >= circles.length) {
                clearInterval(interval)
                setTimeout(() => {

                    this.$emit('lineComplete')
                }, this.getSpawnTime)
                return;
            }
            let index = randomIndexes[counter]
            let circle = circles[index]

            circle['x'] = index

            this.gameCircles.push(circle)
            counter++
        }, this.getLineIntervalTime)

    }
}

</script>