<template>
    <p class="auth-container-title">Reset Your Password</p>
    <p class="error" v-if="error_text">
        <span class="font-small">{{ error_text }}</span>
    </p>
    <p class="caption">* Password should be 8 characters or more</p>
    <div class="form-container">
        <label for="Password">New Password</label>
        <input type="password" name="password1" :aria-invalid="!isPasswordLong" v-model="password1">
        <p class="error-text font-small" v-if="!isPasswordLong">*Password not long enough</p>
    </div>
    <div class="form-container">
        <label for="Password">Confirm Password</label>
        <input type="password" name="password2" :aria-invalid="!doPasswordsMatch" v-model="password2">
        <p class="error-text font-small" v-if="!doPasswordsMatch">*Passwords do not match</p>
    </div>

    <LoadingButton class="primary-btn" :loading="loading" :disabled="!(isPasswordValid)" @click="resetPassword">
        <div class="row">

            Reset My Password
        </div>
    </LoadingButton>
</template>

<script>
import LoadingButton from '@/components/buttons/LoadingButton.vue'
import { useToast } from 'vue-toastification'
var toast = useToast()
export default {
    components: {
        LoadingButton
    },
    data() {
        return {
            password1: null,
            password2: null,
            loading: false,
            error_text: null
        }
    },

    computed: {
        doPasswordsMatch() {
            if (this.password2 != null) {

                return this.password1 == this.password2
            }
            return true
        },
        isPasswordLong() {
            if (this.password1 != null) {
                return this.password1.length >= 8
            }
            return true
        },
        isPasswordValid() {
            if (this.password1 != null && this.password2 != null) {
                return this.doPasswordsMatch && this.isPasswordLong
            }
            return false
        }
    },
    methods: {
        resetPassword() {
            if (this.isPasswordValid) {
                this.error_text = null
                var uid = this.$route.params['uid']
                var token = this.$route.params['token']
                this.loading = true
                this.$store.dispatch('user/resetPassword', {
                    new_password1: this.password1,
                    new_password2: this.password2,
                    uid: uid,
                    token: token
                }).then(e => {

                    if (e.status <= 210) {
                        toast.success("Password reset successfully")
                        this.$router.push({ name: 'login' })
                    }
                    else {
                        toast.error("Password reset failed")
                    }
                }).catch(e => {
                    if (e.response) {
                        if (e.response.status < 500) {

                            this.error_text = e.response.data.error
                        }


                    }
                    toast.error("Password reset failed")

                }).finally(() => {
                    this.loading = false
                })
                // Reset password
            }
        }
    }
}
</script>