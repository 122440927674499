<template>
    <div id="game-details-container">

        <div class="score-container">
            <div id="score-count" class='score-container-details'>
                <img src="@/assets/icons/bullseye.svg" alt="score" class="score-container-icon">
                <p class="score-text">{{ getScore }}</p>
                <ScoreStatus status="score_inc=='true'" v-if="getScore!=0" />
            </div>
            <div id="money-count" class='score-container-details'>
                <img src="@/assets/icons/dollar.svg" alt="cash" class="score-container-icon">
                <p class="score-text">{{ getMoney }}</p>
                <ScoreStatus status="money_inc=='true'" v-if="getScore!=0" />
            </div>
        </div>
        <!-- <div class="score-details-container">
            <div class="score-details">
                <p>Level: {{ getLevel }}</p>
                <div class="row">
                    <div class="score-detail">

                        Missed: {{ getMissed }} 
                    </div>
                    <div class="score-detail">

                        Correct: {{ getCorrect }} 
                    </div>
                    <div class="score-detail">

                        Wrong: {{ getWrong }} 
                    </div>
                </div>
            </div>
        </div> -->
    
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScoreStatus from './ScoreStatus'
export default {
    components: {
        ScoreStatus
    },

    computed:{
        ...mapGetters('game',[
            'getLevel','getScore','getMissed','getCorrect','getWrong','getMoney'
        ])
    },
    props:{
    
        
    }
}
</script>