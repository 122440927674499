import api from "@/utils/axios_util";
import { useToast } from "vue-toastification";
var toast = useToast();
export default {
  namespaced: true,
  state: {
    balance: 0,
    transactions: [],
    amount_to_deposit: null,
    checkout_id: null,
    amount_to_withdraw: null,
    isDepositSideBarOpen: false,
    isWithdrawSideBarOpen: false,
  },
  mutations: {
    setBalance(state, balance) {
      state.balance = balance;
    },
    setTransactions(state, transactions) {
      state.transactions = transactions;
    },
    addTransaction(state, transaction) {
      state.transaction.push(transaction);
    },
    setAmountToDeposit(state, amount) {
      state.amount_to_deposit = amount;
    },
    setCheckoutId(state, id) {
      state.checkout_id = id;
    },
    setAmountToWithdraw(state, amount) {
      state.amount_to_withdraw = amount;
    },
    toggleDepositSideBar(state) {
      state.isDepositSideBarOpen = !state.isDepositSideBarOpen;
    },
    toggleWithdrawSideBar(state) {
      state.isWithdrawSideBarOpen = !state.isWithdrawSideBarOpen;
    },
  },
  getters: {},
  actions: {
    getBalance({ commit }) {
      api
        .get("wallet/balance/")
        .then((response) => {
          commit("setBalance", response.data.balance);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTransactions({ commit }) {
      api
        .get("wallet/transaction/")
        .then((response) => {
          commit("setTransactions", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async lipaNaMpesa({ commit }, { phone }) {
      return api
        .post("wallet/mpesa/stk-push/", {
          phone_number: phone,
          amount: this.state.wallet.amount_to_deposit,
        })
        .then((response) => {
          console.log(response);
          var data = response.data;
          var checkout_id = data.CheckoutRequestID;
          commit("setCheckoutId", checkout_id);
        })
        .catch((e) => {
          console.log(e);
          toast.error("An error has occurred. Try again");
        });
    },
    async checkMpesaPayment({ state }) {
      return api.post("wallet/mpesa/stk-push/verify/", {
        checkout_id: state.checkout_id,
      });
    },
    async withdrawToMpesa({ state, dispatch }, { phone }) {
      return api
        .post("wallet/mpesa/withdraw/", {
          amount: state.amount,
          phone_number: phone,
        })
        .then(() => {
          dispatch("initialize");
          toast.success(
            "Withdrawal request sent. You will be informed when processing is complete"
          );
        })
        .catch((e) => {
          console.log(e);
          toast.error("An error has occurred. Try again");
        });
    },

    initialize({ dispatch }) {
      dispatch("getBalance");
      dispatch("getTransactions");
    },
  },
};
