<template>
    <SideBar id="deposit-panel">
        <!-- <WalletAmount v-if="section == 1" /> -->
        <WalletPayment v-if="section == 1" @methodSelected="methodSelected" :withdraw="true"/>
        <MpesaPayment v-if="section==2 && method==='mpesa'" :withdraw="true"/>
        <CreditPayment v-if="section==2 && method==='credit'"/>
    </SideBar>
</template>

<script>
import SideBar from '../SideBar.vue';
import WalletPayment from "../wallet/WalletPayment"
import MpesaPayment from '../wallet/MpesaPayment.vue';
import CreditPayment from '../wallet/CreditPayment.vue';
export default {
    name: 'DepositPanel',
    data() {
        return {
            section: 1, method: null
        }
    },
    methods: {
        methodSelected(e) {
            this.method = e;
            this.section = 2
            // this.$emit("methodSelected",e)
        }
    },
    components: {
        SideBar, WalletPayment, MpesaPayment, CreditPayment
    }
}
</script>