<template>
    <div class="game-view">
        <RouterView />

    </div>
</template>

<script>

import { useToast } from 'vue-toastification'

const toast = useToast()
import { mapGetters } from 'vuex';
export default {
    name: 'GameView',
    components: {

    },

    methods: {

        clearGameState() {
            this.$store.dispatch('game/clearState')

        },
        gameOver() {
            // this.clearGameState()
            this.$store.dispatch('game/gameOver').then(res => {
                console.log(res)
            }).catch(e => {
                console.log(e)
                toast.error("Could not sync to server.")
                //TODO: Handle exception better
            })
            this.$router.replace({ name: 'game_over' })
        },

    },
    computed: {

        ...mapGetters('game', ['isGameOver', 'getTotal', 'getScore', 'getGameData', 'getCompletedCircles'])

    },
    watch: {
        getCompletedCircles(value) {
            if (this.getGameData.length != 0 && value === this.getGameData.length) {
                this.gameOver()
            }
        }
    },

}

</script>