<template>
    <title>N2 | Register with Email</title>
    <div class="container">

        <p class="auth-container-title">Sign up to N2</p>
        <div class="error-container margin-top" v-if="auth_error">
            <p>{{ auth_error }}</p>
        </div>

        <div class="form-container">
            <label for="email">Email Address</label>
            <input type="email" name="email" v-model="email" :aria-invalid="!isEmailValid" autocomplete="email">
            <p class="input-info f500">*This field is required</p>

            <p class="error-text font-small f500" v-if="!isEmailValid">*Enter a valid email address</p>

        </div>
        <div class="form-container">
            <label for="username">Username</label>
            <input type="text" name="username" v-model="username" :aria-invalid="!isUsernameValid" autocomplete="username">
            <p class="input-info f500">*This field is required</p>

        </div>
        <div class="form-container">
            <label for="password1">Password</label>
            <input type="password" name="password1" minlength="8" v-model="password1" :aria-invalid="!isPassword1Valid">
            <p class="input-info f500">*Should be 8 characters or more</p>
            <p class="error-text font-small f500" v-if="!isPassword1Valid">*Password is not long enough</p>

        </div>
        <div class="form-container">
            <label for="password2">Confirm Password</label>
            <input type="password" name="password2" minlength="8" v-model="password2" :aria-invalid="!isPassword2Valid">
            <p class="error-text font-small f500" v-if="!isPassword2Valid">*Passwords do not match</p>
        </div>
        <LoadingButton class="btn primary-btn" @click="createAccount" :disabled="!isValid" :loading="loading">Create
            Account</LoadingButton>


        <!-- <p class="auth-link-redirect" id="forgot-pass-login">Forgot Password? <a>Reset Password</a></p> -->

    </div>
    <div class="auth-container-footer">
        <p class="auth-link-redirect">Have an account? <router-link :to="{ name: 'login' }">Login</router-link></p>
    </div>
</template>
<script>
import LoadingButton from '@/components/buttons/LoadingButton.vue';
import { useToast } from 'vue-toastification';

var toast = useToast()
export default {

    computed: {
        isEmailValid() {
            if (this.email != null || this.email !== "") {

                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(this.email);
            }
            return true

        },
        isUsernameValid() {
            if (this.username != null || this.username !== "") {
                return true
            }
            return false
        },
        isPassword1Valid() {
            if (this.password1 != null && this.password1.length >= 8) {
                return true
            }
            return false
        },
        isPassword2Valid() {
            if (this.password2 != null && this.password1 === this.password2) {
                return true
            }
            return false
        },
        isValid() {
            if (this.isEmailValid && this.isPassword1Valid && this.isPassword2Valid && this.isUsernameValid) {
                return true
            }
            return false
        }
    },
    components: {
        LoadingButton
    },
    methods: {


        createAccount() {
            if (this.isValid) {
                this.auth_error = null
                this.loading = true;
                this.$store.dispatch('user/register', {
                    email: this.email,
                    password1: this.password1,
                    password2: this.password2,
                    username: this.username
                }).then(() => {
                    toast.success("Account creation successful. Verify your email")
                    this.$router.push({ name: 'register-email-verify' })
                }).catch(e => {
                    console.log(e.response.data)
                    if(e.response){
                        var data = e.response.data
                        this.auth_error = Object.values(data).join(", ")
                        
                    }

                    toast.error("An error has occured.")
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        register() {

        }
    },
    data() {
        return {
            email: "",
            password1: "",
            password2: "",
            username: "",
            loading: false,
            auth_error: null
        }
    }
}

</script>