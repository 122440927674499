<template>
    <div class="score-status">
        <img src="@/assets/icons/up-arrow-green.svg" alt="high" id="score-status-high" v-show="status=='true'">
        <img src="@/assets/icons/down-arrow-red.svg" alt="low" id="score-status-low" v-show="status=='false'">
    </div>
</template>

<script>
export default{
    name: 'ScoreStatus',
    props: {
        status: {
            required: true
        }
    }
}
</script>