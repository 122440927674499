<template>
    <BaseModal @close="$emit('close')">
        <template v-slot:header>
            <h4 class="title">

                Complete Your Stake
            </h4>
        </template>
        <template v-slot:body>

            <div class="stake_details">
                <div class="center-container">

                    <div class="column">
                        <p class="label">Staked Amount</p>
                        <p class="value stake_amount">${{ stake_amount }}</p>
                    </div>
                </div>
                <div class="row space-btn">

                    <div class="column">
                        <p class="label">Difficulty Level</p>
                        <DifficultyCard :level="game_level" :selected="false" class="value" />

                    </div>
                    <div class="column">
                        <p class="label">Stake Level</p>
                        <DifficultyCard name="21 - 27" :level="stake_level" :selected="false" class="value" />
                    </div>

                </div>
                <div class="possibility">
                    <div class="center-container">

                        <div class="column">
                            <p class="label">Possible Payout</p>
                            <p class="value stake_amount" id="possible-payout-text">${{ payout }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="row space-btn">
                <p id="modal-cancel" @click="$emit('close')">Cancel</p>
                <LoadingButton class="primary-btn" @click="placeStake" :loading=loading>
                    Stake & Play
                </LoadingButton>
            </div>

        </template>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import DifficultyCard from '@/components/DifficultyCard.vue'
import LoadingButton from '@/components/buttons/LoadingButton.vue'
// import { mapActions } from 'vuex';
export default {
    components: {
        BaseModal,
        DifficultyCard,
        LoadingButton
    },
    data() {
        return {
            loading: false,
            stake_error: null,
            payout: 0.0
        }
    },
    computed: {
        
    },
    methods: {

        getPossiblePayout(){
            var game_level = this.$props.game_level;
            var bet_level = this.$props.stake_level
            var amount = this.$props.stake_amount
            this.$store.dispatch('game/getPossiblePayout',{
                bet_level:bet_level,
                game_level:game_level,
                amount:amount
            }).then(e=>{
                this.payout = e
            })

        },

        placeStake() {
            this.loading = true;
            this.$emit('close')
            this.$emit('playGame')
            this.loading = false
        }
    },
    mounted(){
        this.getPossiblePayout()
    },
    props: {
        stake_amount: {
            type: Number,
            default: 0
        },
        stake_level: {
            type: Number,
            default: 2
        },
        game_level: {
            type: Number,
            default: 3,
        }
    }
}
</script>