<template>
    <div class="chipper" :class="{ selected: selected }">
        <p class="chipper-text">{{ label }}</p>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            required: true,
            type: String
        },
        selected: {
            type: Boolean,
            default: false
        }
    }
}

</script>