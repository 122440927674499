<template>
    <title>N2 | Login</title>
    <div class="container">

        <p class="auth-container-title">Welcome Back, <span>Login</span></p>
        <div class="form-container">

            <div class="alternative-auth">
                <div class="alternative-auth-buttons">
                    <GoogleButton :primary="false" />
                   
                </div>
            </div>
        </div>
        <hr class="divider sign-in">
        <p class="error-text f600 font-small" v-if="error_message">*{{ error_message }}</p>

        <div class="form-container">
            <label for="Username">Email Address</label>
            <input type="email" v-model="email" :aria-invalid="!isEmailValid">
        </div>
        <div class="form-container">
            <label for="Password">Password</label>
            <input type="password" v-model="password" :aria-invalid="!isPasswordValid">
        </div>
        <LoadingButton class="btn primary-btn" @click="login" :disabled="!isValid" :loading="loading">
            Login
        </LoadingButton>

        <p class="auth-link-redirect" id="forgot-pass-login">Forgot Password? <router-link
                :to="{ name: 'password-reset-new' }">Reset Password</router-link></p>

    </div>
    <div class="auth-container-footer">
        <p class="auth-link-redirect">Don't have an account? <router-link :to="{ name: 'register' }">Register</router-link>
        </p>
    </div>
</template>
<script>
import GoogleButton from '@/components/buttons/GoogleButton.vue'
import LoadingButton from '@/components/buttons/LoadingButton.vue'
import { useToast } from 'vue-toastification'
var toast = useToast()
export default {
    name: 'LoginView',
    components: {
        GoogleButton,
        LoadingButton
    },
    data() {
        return {
            email: '',
            password: '',
            loading: false,
            error_message: null
        }
    },

    computed: {
        isEmailValid() {
            if (this.email != null || this.email !== "") {

                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailPattern.test(this.email);
            }
            return true
        },
        isPasswordValid() {
            if (this.password !== "") {
                return true
            }
            return false
        },
        isValid() {
            if (this.email != "" && this.isEmailValid && this.password != "" && this.isPasswordValid) {
                return true
            }
            return false
        }

    },
    methods: {
        login() {
            if (this.isValid) {
                this.loading = true
                this.$store.dispatch('user/login', {
                    username: this.email,
                    password: this.password
                }).then(() => {
                    this.$router.push({ name: 'home' }).then(() => {
                        location.reload()
                    })

                }).catch(e => {
                    if (e.response) {
                        var data = e.response.data
                        if (data.non_field_errors) {

                            this.error_message = data.non_field_errors[0]
                        }
                        else {
                            toast.error("Could not login. Check your credentials")
                        }
                    }
                    else {
                        toast.error("Something went wrong. Try again")
                    }
                }).finally(() => {
                    this.loading = false
                })

            }
        }
    }


}
</script>