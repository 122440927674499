<template>
    <div class="home-feature-card">
        <div class="home-feature-card-icon">
            <div class="icon-container">
                <slot name="icon"></slot>

            </div>
        </div>
        <div class="container">
            <p class="home-feature-card-title">{{ title }}</p>
            <p class="home-feature-card-description">{{ description }}</p>
        </div>
    </div>
</template>
<script>

export default {
    name: "FeatureCard",
    props: {
        title: String,
        description: String,
    }
}
</script>