<template>
    <BaseModal>
        <template v-slot:header>
            <p class="sub-title">Withdraw from your wallet</p>

        </template>
        <template v-slot:body>
            <div class="center-container">
                <p class="medium-text f500">Wallet Balance: <span class="f600">{{ balance }}.00</span></p>
            </div>
            <div class="form-container margin-top">
                <label for="Amount"></label>
                <div class="row">
                    <p class="currency-indicator">
                        KSH
                    </p>
                    <input type="number" class="small-input normal-input" placeholder="Amount" v-model="selectedAmount"
                        min="1" :aria-invalid="!isValid">
                </div>
                <p class="error-text margin-top font-small" v-show="errorMessage">{{ errorMessage }}</p>
            </div>
        </template>
        <template v-slot:footer>
            <button class="primary-btn big-btn full-width-btn margin-top" id="deposit-amount-btn" @click="buttonClick"
                :disabled="selectedAmount && !isValid">
                <div class="row">
                    Proceed to Withdrawal Method
                    <i class="fas fa-chevron-right"></i>
                </div>
            </button>
        </template>

    </BaseModal>
</template>

<script>
import { mapState } from 'vuex';
import BaseModal from './BaseModal.vue';
export default {
    components: {
        BaseModal
    },
    data() {
        return {
            selectedAmount: null
        }
    },
    computed: {
        ...mapState('wallet', ['balance']),
        isValid() {
            if (this.selectedAmount > this.balance) {
                return false
            }
            return true

        },
        errorMessage() {
            if (this.selectedAmount > this.balance) {
                return "* Amount is greater than wallet balance"
            }
            return null

        }
    },
    methods: {
        buttonClick() {
            if (this.isValid) {

                this.$store.commit('wallet/setAmountToWithdraw', this.selectedAmount);
                this.$emit("amountSetComplete", this.selectedAmount)
            }
            else {
                this.$toast.warning("Please select an amount to withdraw")
            }
        }
    }
}
</script>